<template>
	<v-container ref="qrcode-container" class="printqr" grid-list-xl text-center>
		<v-flex xs12 class="hide-on-printqr">
			<span class="display-3 font-weight-bold primary--text">Order Waybill</span>
		</v-flex>
		<v-flex xs12 class="ma-5">
			<v-btn v-show="qrdata.length" @click="print()" class="hide-on-printqr accent">Print</v-btn>
		</v-flex>
		<v-card
			flat
			v-for="(tag, index) in qrdata"
			:key="index"
			class="leaf"
			:class="index === 0 ? (qrdata.length === index + 1 ? 'first last' : 'first') : qrdata.length === index + 1 ? 'last' : ''"
		>
			<v-flex xs12>
				<v-row>
					<v-col cols="12" class="pt-0">
						<label>Customer</label>
						<div>
							{{ selectedOrder.customer != null ? selectedOrder.customer.name : selectedOrder.organisation.name }}
						</div>
					</v-col>
					<v-col cols="4" class="outline delivery-address">
						<label>Pickup Address:</label>
						<div v-if="orderAddress[0].complexName">
							{{ orderAddress[0].complexName }}
						</div>
						<div>{{ orderAddress[0].streetNumber }} {{ orderAddress[0].streetName }}</div>
						<div>{{ orderAddress[0].province }}</div>
						<div>{{ orderAddress[0].city }}</div>
					</v-col>
					<v-col cols="4" class="outline">
						<div v-html="imgTagArray[index]"></div>
						<span class="primary--text">Order: {{ tag }}</span>
						<v-spacer></v-spacer>
					</v-col>
					<v-col cols="4" class="outline delivery-address">
						<label>Delivery Address:</label>
						<div v-if="selectedOrder.address.complex">{{ selectedOrder.address.complex }}</div>
						<div>{{ selectedOrder.address.streetNumber }} {{ selectedOrder.address.streetName }}</div>
						<div>{{ selectedOrder.address.province }}</div>
						<div>{{ selectedOrder.address.city }}</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="text-right pa-0 pr-5 footer-index">{{ index + 1 }} of {{ qrdata.length }} </v-col>
				</v-row>
			</v-flex>
		</v-card>
		<v-flex xs12 class="ma-5">
			<v-btn v-show="qrdata.length" @click="print()" class="hide-on-printqr accent">Print</v-btn>
		</v-flex>
	</v-container>
</template>

<script>
import Vue from "vue";
import * as qr from "qrcode-generator";
import { Handler } from "leaflet";

export default Vue.extend({
	name: "OrganisationOrderQRCode",

	props: {
		orderId: {
			type: String,
			default: "0"
		},
		from: {
			type: String,
			default: "view-order"
		}
	},

	computed: {
		selectedOrder: function() {
			return this.$store.state.organisations.activeOrders.selectedOrder;
		},
		orderAddress: function() {
			return this.$store.state.organisations.activeOrders.orderAddress;
		}
	},

	watch: {
		selectedOrder: {
			deep: true,
			immediate: true,
			handler(newVal, oldVal) {
				if (oldVal != null) {
					this.$nextTick(function() {
						this.draw(newVal.parcels.count);
					});
				}
			}
		}
	},

	data: function() {
		return {
			imgTag: "",
			qrdata: [],
			printing: false,

			imgTagArray: [],

			order: {},
			address: {}
		};
	},

	created: function() {
		this.$store.dispatch("organisations/activeOrders/fetchOrderPickupAddress");
		this.$store.dispatch("organisations/activeOrders/fetchOrder", this.$props.orderId);
		this.order = this.selectedOrder;
	},

	methods: {
		onSelectedOrderChange(newVal, oldVal) {
			if (oldVal != null) {
				this.$nextTick(function() {
					this.draw(newVal.parcels.count);
				});
			}
		},
		async draw(parcels) {
			let numberOfPackages = parcels;
			for (let i = 1; i <= numberOfPackages; i++) {
				let qrCode = qr.default(0, "L");

				const orderId = this.$props.orderId;
				let packageId = "";

				packageId = (orderId + "." + i).toString();

				this.qrdata.push(packageId);

				qrCode.addData(packageId);
				qrCode.make();

				this.imgTagArray.push(qrCode.createImgTag(4, 5));
			}
		},
		async print() {
			await this.$nextTick();
			this.printing = true;

			let section = document.getElementById("print");

			// clear element if order was previously printed
			if (section) {
				const element = document.getElementById("print");
				if (element != null) {
					element.outerHTML = "";
				}
			}

			window.print();
		}
	}
});
</script>

<style>
@media screen {
	.leaf {
		margin: 15px;
		padding: 30px;
		display: inline-block;
		width: 360px;
	}

	.outline {
		padding: 0;
		text-align: center;
	}

	.delivery-address {
		font-size: 13px;
	}

	label {
		font-size: 9px;
		font-weight: bold;
		white-space: nowrap;
	}
	.footer-index {
		font-size: 9px;
	}
}

@media print {
	body * {
		visibility: hidden;
		padding: 0 !important;
	}

	main {
		padding: 0 !important;
		margin: 0 !important;
		width: 100%;
		height: 100%;
	}

	header,
	footer {
		display: none !important;
	}

	.printqr * {
		visibility: visible;
	}

	.hide-on-printqr {
		display: none !important;
	}

	.primary--text {
		font-weight: bold !important;
		text-transform: uppercase !important;
	}

	.leaf {
		position: relative;
		top: 20px;
		left: 0;
		right: 0;
		bottom: 0;
		page-break-after: always;
		margin: 0 auto;
		transform: rotate(180deg);
		text-align: center;
		height: 180px;
	}

	.leaf.first {
		top: 0; /* first page has unwanted margin */
	}

	.leaf.last {
		page-break-after: avoid;
	}

	.delivery-address {
		font-size: 13px;
	}

	label {
		font-size: 9px;
		font-weight: bold;
		white-space: nowrap;
	}

	.footer-index {
		font-size: 9px;
	}
}
</style>
